import get from 'lodash.get';

export const hcpFormPopupTransform = (query) => {
  const queryData = query.nodeQuery.entities[0];

  const data = {
    apiEndpoint: get(queryData, 'fieldVali.entity.fieldApiEndpoint.url.path', '-'),
    checkInResponseHasProfessionId: get(
      queryData,
      'fieldVali.entity.fieldCheckInRespProfId',
      false
    ),
    checkInResponseIsRegistered: get(
      queryData,
      'fieldVali.entity.fieldCheckInRespRegistered',
      false
    ),
    isExist: get(queryData, 'fieldVali', false),
    title: get(queryData, 'fieldVali.entity.fieldTitle', '-'),
    description: get(queryData, 'fieldVali.entity.fieldDescription', '-'),
    label: get(queryData, 'fieldVali.entity.fieldLabel', '-'),
    submit: get(queryData, 'fieldVali.entity.fieldSubmit', '-'),
    goBack: get(queryData, 'fieldVali.entity.fieldBack', '-'),
    placeholder: get(queryData, 'fieldVali.entity.fieldPlaceholder', '-'),
    errorMessageTooMany: get(queryData, 'fieldVali.entity.fieldErrorMessageTooMany', ''),
    errorMessageTooLittle: get(queryData, 'fieldVali.entity.fieldErrorMessageTooLittle', ''),
    errorMessageNumberError: get(queryData, 'fieldVali.entity.fieldErrorMessageNumberError', ''),
    errorMessageIsEmpty: get(queryData, 'fieldVali.entity.fieldErrorMessageIsEmpty', ''),
    errorMessageIncorrect: get(queryData, 'fieldVali.entity.fieldErrorMessageIncorrect', ''),
    numberLength: get(queryData, 'fieldVali.entity.fieldNumberLength', 1),
    includeOnPages: get(queryData, 'fieldVali.entity.fieldIncludeOnPages', [])
      .map(item => {
        const itemPath = get(item, 'entity.path.alias', '');
        const itemLabel = get(item, 'entity.entityLabel', '');

        return itemLabel === 'Home' ? '/hcp' : itemPath;
      })
      .filter(item => item !== '' && item !== null),
  };

  return data;
};
