export const colors = {
  text: {
    default: '#464040',
    light: '#867C89',

    hcpHeader: '#F03C3C',
    patientHeader: '#1E3273',
  },
  ui: {
    // patient variant
    patientDefault: '#1E3273',
    patientDark: '#0E1C48',
    patientDefaultTransparent: 'rgba(30, 50, 115, 0.85)',

    // hcp variant
    hcpDefault: '#F03C3C',
    hcpDark: '#D71010',
    hcpDefaultTransparent: 'rgba(240, 60, 60, 0.85)',

    tableRow: '#F2FBFB',
    tableBorder: '#E5E3E6',
    accordionHeader: '#BF0D0D',
    cookieBanerBg: '#F2FBFB',
    accordionSymptomsTitle: '#24356E',
    accordionBodyBg: '#DCDBDD',

    accept: '#138601',
    acceptDark: '#0e5802',

    spinnerBorder: '#f3f3f3',
  },
  white: '#fff',
  black: '#000',
  transparentLight: 'rgba(255, 255, 255, 0.36)',
  transparentDark: 'rgba(0, 0, 0, 0.62)',
  footerText: '#707070',
  footerMap: '#A50054',
  warningBackground: '#FEF6F6',
  warningText: '#EA1010',
  eventDisabled: '#D8D4D9',
};

export const fonts = {
  default: 'Montserrat, sans-serif',
  chinese: 'Noto Sans TC, sans-serif',
};

export const breakpoints = {
  sm: 360,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1400,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1100,
};

export const dimensions = {
  fontSize: {
    large: 25,
    lg_md: 16,
    regular: 14,
    small: 12,
    tiny: 10,
  },
  lineHeight: {
    regular: 1.3,
    heading: 1.2,
    list: 1.45,
  },
};

export const animation = {
  fast: 200,
  slow: 350,
  subtle: 500,
};

export const heights = {
  header: 100,
};
