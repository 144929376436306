import React, { useState } from 'react';

// === Components === //
import {
  Button,
  PopupContent,
  PopupFooterInner,
  PopupFooterWrapper,
  PopupHeader,
  PopupInner,
  PopupWrapper,
} from '@components/layout/ExternalLinksPopup';

// === Types === //
import { HcpFormPopupData } from '@content/types/layout';

// === Helpers === //
import Cookies from 'js-cookie';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as utils from '@utils';
import * as yup from 'yup';

// === Styles === //
import styled from '@emotion/styled';
import { colors, dimensions, fonts, mixins } from '@styles';

export const SubmitButton = styled(Button)`
  background-color: ${colors.ui.hcpDefault};
  cursor: pointer;
  ${mixins.transitionDefault};
  display: flex;
  gap: 8px;
  &:hover {
    background-color: ${colors.ui.hcpDefaultTransparent};
  }
`;

export const BackButton = styled(Button)`
  background-color: ${colors.ui.patientDefault};
  cursor: pointer;
  ${mixins.transitionDefault};
  display: flex;
  gap: 8px;
  &:hover {
    background-color: ${colors.ui.patientDefaultTransparent};
  }
`;

export const Input = styled.input`
  border: 1px solid #1e3273;
  border-radius: 10px;
  font-family: ${fonts.default};
  padding: 7px 10px;
  min-width: 220px;
`;

export const Label = styled.label`
  padding-right: 10px;
`;
export const FieldWrapper = styled.div`
  margin-bottom: 5px;
  margin-top: 10px;
`;

export const WarningText = styled.span`
  color: ${colors.warningText};
  font-size: ${dimensions.fontSize.tiny}px;
`;

export const Loader = styled.div`
  border: 2px solid ${colors.ui.spinnerBorder};
  border-radius: 50%;
  border-top: 2px solid ${colors.ui.hcpDefaultTransparent};
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  width: 14px;
  height: 14px;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface HcpFormPopupPopupProps {
  open: boolean;
  handleClose: () => void;
  formPopupData: HcpFormPopupData;
}

interface FormValues {
  number: string;
}

const HcpFormPopup = ({ open, handleClose, formPopupData }: HcpFormPopupPopupProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues: FormValues = {
    number: '',
  };
  const numberLength = formPopupData.numberLength;

  const validationSchema = yup.object().shape({
    number: yup
      .string()
      .required(formPopupData.errorMessageIsEmpty)
      .matches(/^[0-9]+$/, formPopupData.errorMessageNumberError)
      .min(numberLength, formPopupData.errorMessageTooLittle)
      .max(numberLength, formPopupData.errorMessageTooMany),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsLoading(true);
    const request = await fetch(
      `${formPopupData.apiEndpoint.replace('/{number}', '')}/${data.number}`,
      {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      }
    );
    const response: { data?: { isRegistered?: boolean; professionId?: number } } =
      await request.json();

    if (
      (formPopupData.checkInResponseIsRegistered ? response?.data?.isRegistered : true) &&
      (formPopupData.checkInResponseHasProfessionId ? response?.data?.professionId === 1 : true)
    ) {
      // save in cookies for 1h
      Cookies.set('rizv-check', '1', {
        expires: 1 / 24,
      });
      handleClose();
      setIsLoading(false);
    } else {
      setError('number', { message: formPopupData.errorMessageIncorrect });
      setIsLoading(false);
    }
  };

  const onGoBack = () => {
    if (typeof window !== 'undefined') {
      Cookies.remove('rethinkFabry-view');
      window.location.href = '/';
    }
  };

  return (
    <PopupWrapper open={open}>
      <PopupInner open={open}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <PopupHeader>
            <span>{formPopupData.title}</span>
          </PopupHeader>
          <PopupContent>
            {utils.SafeHtml(formPopupData.description)}
            <FieldWrapper>
              <Label>{formPopupData.label}</Label>
              <Input type="text" {...register('number')} placeholder={formPopupData.placeholder} />
            </FieldWrapper>
            {errors.number && <WarningText role="alert">{errors.number.message}</WarningText>}
          </PopupContent>
          <PopupFooterWrapper>
            <PopupFooterInner>
              <BackButton type="button" onClick={onGoBack}>
                {formPopupData.goBack} {isLoading && <Loader />}
              </BackButton>
              <SubmitButton type="submit">
                {formPopupData.submit} {isLoading && <Loader />}
              </SubmitButton>
            </PopupFooterInner>
          </PopupFooterWrapper>
        </form>
      </PopupInner>
    </PopupWrapper>
  );
};

export default HcpFormPopup;
